<template>
  <v-card
    class="pa-6 d-flex"
    :class="$vuetify.breakpoint.mdAndUp ? 'pt-15' : 'pt-10'"
    flat
    rounded="xl"
    style="
      flex-direction: column;
      gap: 24px;
      background: var(--v-background_light-base);
    "
  >
    <v-avatar
      :style="
        $vuetify.breakpoint.lgAndUp
          ? 'width: 200px; height: 200px'
          : 'width: 140px; height: 140px'
      "
      class="d-flex mx-auto"
    >
      <v-img :src="userData.photoURL" />
    </v-avatar>
    <div class="mx-auto">
      <span class="h2 font-weight-bold primary--text">
        {{ $i18n.locale == "ko" ? "&#8361;" : "&#36;" }} 0
      </span>
      <span class="subtitle-1 font-weight-bold primary--text">
        {{ $t("mypage.point") }}
      </span>
    </div>
    <div class="d-flex" style="flex-direction: column; gap: 12px">
      <div
        v-for="u in this.userProfiles"
        :key="u.key"
        class="d-flex pa-3 content_box"
      >
        <div class="d-flex my-auto" style="gap: 12px">
          <v-avatar size="36" color="primary">
            <v-icon class="ma-auto" color="white_light_global">
              {{ u.icon }}
            </v-icon>
          </v-avatar>
          <div class="d-flex-column my-auto">
            <div class="subtitle-2 font-weight-bold onSurface_light--text">
              {{ $t("mypage.userData." + u.value) }}
            </div>
            <div class="subtitle-1 font-weight-bold onSurface_dark--text">
              {{ userData[u.value] || "-" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-btn
        block
        class="title-1 font-weight-bold"
        color="primary"
        height="54px"
        :style="$vuetify.breakpoint.mdAndDown ? 'width: 240px' : 'width: 280px'"
        style="border-radius: 12px"
        @click="$router.push('/mypage/edit')"
      >
        {{ $t("mypage.editProfile") }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MyPage-ProfileCard",
  props: { userData: Object },
  data() {
    return {
      userProfiles: [
        {
          key: "사용자명",
          value: "displayName",
          icon: "mdi-account",
        },
        {
          key: "닉네임",
          value: "nickName",
          icon: "mdi-emoticon",
        },
        { key: "이메일", value: "email", icon: "mdi-email" },
        {
          key: "연락처",
          value: "phoneNumber",
          icon: "mdi-phone",
        },
      ],
    };
  },
};
</script>

<style>
.content_box {
  outline: 1px solid var(--v-background_normal-base);
  border-radius: 12px;
  background-color: var(--v-primary_light-base);
  height: 64px;
}
</style>
