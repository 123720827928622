<template>
  <div
    class="d-flex"
    style="gap: 36px"
    :style="$vuetify.breakpoint.smAndDown && 'flex-direction: column'"
  >
    <div>
      <profile-card :userData="userData" />
    </div>
    <div class="d-flex" style="gap: 36px; flex-direction: column; width: 100%">
      <order-card :userData="userData" :fullscreen="false" />
      <div
        class="d-flex"
        style="gap: 36px; width: inherit"
        :style="$vuetify.breakpoint.mdAndDown && 'flex-direction: column'"
      >
        <maker-card :userData="userData" />
        <company-card :userData="userData" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import ProfileCard from "./components/ProfileCard.vue";
import MakerCard from "./components/MakerCard.vue";
import CompanyCard from "./components/CompanyCard.vue";
import OrderCard from "./components/OrderCard.vue";

export default {
  components: { ProfileCard, MakerCard, CompanyCard, OrderCard },
  name: "MyPage-Home",
  props: { userData: Object, orderData: Array },
  data() {
    return {};
  },
};
</script>
