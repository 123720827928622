<template>
  <v-card
    class="pa-6 d-flex"
    style="
      flex-direction: column;
      gap: 20px;
      height: 100%;
      background: var(--v-background_light-base);
    "
    flat
    rounded="xl"
  >
    <div class="d-flex align-center">
      <div
        class="title-1 font-weight-bold onSurface_dark--text"
        style="width: 80px"
      >
        {{ $t("mypage.orders") }}
      </div>
      <v-spacer />
      <div v-if="fullscreen" class="d-flex">
        <v-text-field
          v-model="searchText"
          style="min-width: 140px; max-width: 280px"
          class="font-weight-regular body-2"
          flat
          dense
          hide-no-data
          hide-details
          solo
          background-color="surface"
          label="주문명"
          @keydown.enter.prevent="
            (searchText != searchedText || searchText == '') && orderSearch()
          "
        >
          <template #append>
            <v-btn
              icon
              :color="
                searchText != searchedText || searchText == '' ? 'primary' : ''
              "
              @click="orderSearch"
              small
              :disabled="searchBtnLoading"
            >
              <v-progress-circular
                indeterminate
                v-if="searchBtnLoading"
                size="20"
                width="3"
                color="primary"
              />
              <v-icon v-else> mdi-magnify </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <div v-else class="d-flex" @click="$router.push('/mypage/orders')">
        <div class="subtitle-1 font-weight-bold my-auto">
          {{ $t("mypage.see_more") }}
        </div>
        <v-icon class="my-auto" size="20"> mdi-chevron-right </v-icon>
      </div>
    </div>
    <div class="d-flex" style="flex-direction: column; gap: 12px; height: 100%">
      <div
        v-for="o in orderData"
        :key="o.id"
        class="d-flex pa-3"
        style="
          height: 120px;
          border-radius: 12px;
          outline: 1px solid var(--v-background_normal-base);
          background: var(--v-primary_light-base);
          flex-direction: column;
        "
        @click="openChat(o)"
      >
        <div class="d-flex subtitle-2 onSurface_light--text">
          <div>
            {{ o.workspace }}
          </div>
          <v-spacer />
          <div>{{ o.createdAt | moment("YYYY.MM.DD") }}</div>
        </div>
        <v-spacer />
        <div class="d-flex">
          <div
            class="title-1 font-weight-bold primary--text"
            style="white-space: nowrap"
          >
            {{ o.purpose }}
          </div>
          <v-spacer />
          <div class="title-1 font-weight-bold onSurface_dark--text">
            {{ o.productCost ? o.productCost.toLocaleString() : "0" }}
          </div>
          <div
            class="subtitle-1 font-weight-bold onSurface_dark--text my-auto ml-1"
          >
            {{ $i18n.locale == "ko" ? "원" : "&#36;" }}
          </div>
        </div>
        <v-spacer />
        <div class="d-flex subtitle-2 onSurface_light--text">
          <v-progress-linear
            :value="progressValue(o.status)"
            color="primary"
            height="12"
            style="border-radius: 6px"
          />
        </div>
        <v-spacer />
        <div class="d-flex title-2 font-weight-bold onSurface_normal--text">
          <div>
            {{ o.file.length }}
            {{ $i18n.locale == "ko" ? "개 파일" : "Files" }}
          </div>
          <v-spacer />
          <div>{{ getStatus(o.status) }}</div>
        </div>
      </div>
      <div
        v-if="
          (!fullscreen && orderData.length < 3) ||
          (fullscreen && orderData.length < 9)
        "
        class="d-flex pa-3"
        style="height: 120px"
      >
        <div class="ma-auto subtitle-2 onSurface_light--text">
          {{ $t("mypage.empty") }}
        </div>
      </div>
      <v-spacer />
      <div class="d-flex" v-if="fullscreen">
        <v-pagination
          class="mx-auto"
          total-visible="11"
          v-model="orderPage"
          :length="orderPageCount"
        >
        </v-pagination>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MyPage-OrderCard",
  props: { userData: Object, fullscreen: Boolean },
  data() {
    return {
      searchText: "",
      searchedText: "",
      searchBtnLoading: false,
      orderData: [],
      orderPage: 1,
      orderPageCount: 1,
    };
  },
  mounted() {
    this.getOrderData();
  },
  watch: {
    userData: {
      handler() {
        this.getOrderData();
      },
    },
    orderPage: {
      // orderPage의 변화를 감시합니다.
      handler() {
        this.getOrderData(); // 페이지가 변경될 때마다 주문 데이터를 다시 불러옵니다.
      },
      immediate: false, // 컴포넌트가 마운트될 때는 호출되지 않도록 설정합니다.
    },
  },
  methods: {
    getOrderData() {
      if (!this.userData.uid) return;
      this.$axios
        .get("orders/", {
          params: {
            uid: this.userData.uid,
            offset: (this.orderPage - 1) * 9,
            limit: this.fullscreen ? 9 : 3,
          },
        })
        .then((v) => {
          if (v) {
            const data = v.data;
            if (this.userData.orderCount) {
              this.orderPageCount = Math.ceil(this.userData.orderCount / 9);
            } else {
              this.$axios
                .get("orders/", {
                  params: {
                    uid: this.userData.uid,
                  },
                })
                .then((v) => {
                  this.$axios.patch("users/" + this.userData.uid, {
                    orderCount: v.data.length,
                  });
                });
            }
            this.orderData = [];
            data.forEach((d) => {
              this.orderData.push(d);
            });
          }
        });
    },
    progressValue(status) {
      switch (status) {
        case 0:
          return (1 / 6) * 100;
        case 1:
          return (2 / 6) * 100;
        case 2:
          return (6 / 6) * 100;
        case 3:
          return (3 / 6) * 100;
        case 4:
          return (4 / 6) * 100;
        case 5:
          return (5 / 6) * 100;
        case 6:
          return (6 / 6) * 100;
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return this.$i18n.locale == "ko" ? "접수 완료" : "File Uploaded";
        case 1:
          return this.$i18n.locale == "ko" ? "파일 검토" : "File Reviewing";
        case 2:
          return this.$i18n.locale == "ko" ? "상담 완료" : "Finished";
        case 3:
          return this.$i18n.locale == "ko" ? "작업 대기" : "Prepare Print";
        case 4:
          return this.$i18n.locale == "ko" ? "작업 진행" : "Printing";
        case 5:
          return this.$i18n.locale == "ko" ? "배송 대기" : "Post Processing";
        case 6:
          return this.$i18n.locale == "ko" ? "작업 완료" : "Shipped";
      }
    },
    openChat(o) {
      window.ChannelIO("openChat", o.channelTalk.chat.id);
    },
    orderSearch() {
      this.searchBtnLoading = true;
      this.$axios
        .get("orders/", {
          params: {
            searchText: this.searchText,
            uid: this.userData.uid,
          },
        })
        .then((v) => {
          if (v) {
            this.orderData = [];
            const data = v.data;
            data.forEach((d) => {
              this.orderData.push(d);
            });
          }
          this.searchBtnLoading = false;
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-progress-linear,
::v-deep .v-progress-linear__background {
  background: var(--v-background_dark-base) !important;
  opacity: 1 !important;
}
::v-deep .v-progress-linear__determinate {
  background: linear-gradient(to right, #36d1dc, #5b86e5) !important;
  border-radius: 6px !important;
}
</style>
