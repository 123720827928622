<template>
  <v-card
    class="pa-6 d-flex"
    style="
      flex: 1;
      flex-direction: column;
      gap: 20px;
      background: var(--v-background_light-base);
    "
    flat
    rounded="xl"
  >
    <div class="d-flex">
      <div class="title-1 font-weight-bold onSurface_dark--text">
        {{ $t("mypage.makerInfo") }}
      </div>
      <v-spacer />
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="20" v-bind="attrs" v-on="on">
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span v-text="$t('mypage.makerInfo_help')" />
      </v-tooltip>
    </div>
    <div class="d-flex" style="flex-direction: column; gap: 12px">
      <div
        v-for="m in makerInfo"
        :key="m.key"
        style="width: inherit"
        class="d-flex pa-3 content_box"
      >
        <div class="d-flex my-auto" style="gap: 12px">
          <v-avatar size="36" color="primary">
            <v-icon class="ma-auto" color="white_light_global">
              {{ m.icon }}
            </v-icon>
          </v-avatar>
          <div class="d-flex-column my-auto">
            <div class="subtitle-2 font-weight-bold onSurface_light--text">
              {{ $t("mypage.maker." + m.value) }}
            </div>
            <div class="subtitle-1 font-weight-bold onSurface_dark--text">
              {{
                userData.makerInfo ? userData.makerInfo[m.value] || "-" : "-"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MyPage-MakerCard",
  props: { userData: Object },
  data() {
    return {
      makerInfo: [
        {
          key: "메이커스페이스",
          value: "makerSpace",
          icon: "mdi-factory",
        },
        {
          key: "회원 ID",
          value: "makerId",
          icon: "mdi-card-account-details-outline",
        },
      ],
    };
  },
};
</script>

<style>
.content_box {
  outline: 1px solid var(--v-background_normal-base);
  border-radius: 12px;
  background-color: var(--v-primary_light-base);
  height: 64px;
}
</style>
